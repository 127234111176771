import {styled} from 'common'
import RawTable from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableTitleAndFilters from './Table/TableTitleAndFilters'
import TableBody from './Table/TableBody'
import TablePagination from './Table/TablePagination'
import TableHeadRow from './Table/TableHeadRow'
import {getTableData} from 'services/utils/tables'
import {useI18n} from 'store/Store'
import {useUrlParams} from 'services/hooks'
import {useEffect, useState} from 'react'
import {FormErrorText} from 'components/Control/Form'
import {INITIAL_PAGE_SIZE} from 'constants/tables'
import Button from 'components/Control/Button'
import {Link} from '@mui/material'
import api from 'services/api'
import {constructUrl} from 'store/slices/api/utils'
import useDebounce from 'logic/Forms/useDebounce'

const getDownloadLink = (
    itemName,
    page,
    pageSize,
    search,
    sort,
    additionalQueries
) => {
    const itemType = itemName === 'ticket' ? 'ticket' : 'user'
    const params = {
        type: itemName === 'ticket' ? undefined : itemName,
        page: page || 0,
        pageSize: pageSize || INITIAL_PAGE_SIZE,
        search,
        sort,
        ...additionalQueries,
    }
    return constructUrl(`${api.baseUrl}/${itemType}s/download`, params)
}

const Table = ({
    itemName,
    itemType,
    total,
    visibleKeys,
    onDelete,
    allowExpand,
    allowEdit,
    allowAdd,
    addFunction,
    editFunction,
    deleteFunction,
    allowDelete,
    allowDownload,
    filters,
    children,
    paginationType,
    useGetQuery,
    useDeleteMutation,
    query = {},
    CustomExpand,
    hideTitle,
    titleTextAlign,
    buttonsGroupOptions,
    selectedToggleButton,
    setSelectedToggleButton,
    onRowClick,
    deletePrompt,
    customRowActions,
    setRowColor,
    ...p
}) => {
    const [checkBoxState, setCheckBoxState] = useState({})
    const [selectedVolunteers, setSelectedVolunteers] = useState([])
    const [doDelete, {error}] = useDeleteMutation()
    const [errorMessage, setErrorMessage] = useState(null)
    const [isShowWhatsAppBtn] = useState(false)
    const [headerCheckboxState, setHeaderCheckboxState] = useState({})

    const {i18n} = useI18n()
    const {urlParams} = useUrlParams()
    const {
        [`${itemName}_search`]: search,
        [`${itemName}_page`]: page,
        [`${itemName}_pageSize`]: pageSize,
        [`${itemName}_sort`]: sort,
        ...filter
    } = urlParams

    const debouncedValue = useDebounce(search, 500)
    const additionalQueries = {...query, ...filter}

    const {data, refetch, isFetching} = useGetQuery(
        paginationType === 'external'
            ? {
                  page: page || 0,
                  pageSize:
                      itemName === 'users_volunteer'
                          ? 10
                          : pageSize || INITIAL_PAGE_SIZE,
                  search: debouncedValue,
                  sort,
                  ...additionalQueries,
              }
            : {...additionalQueries}
    )

    const handleSelectedType = (event, newValue) => {
        if (newValue !== null) {
            setSelectedToggleButton(newValue)
        }
    }

    const handleDelete = (itemId) => {
        deleteFunction ? deleteFunction(itemId) : doDelete(itemId)
    }

    const handleCheckboxChange = (itemId, isChecked) => {
        setCheckBoxState((prevState) => ({
            ...prevState,
            [itemId]: isChecked,
        }))

        setSelectedVolunteers((prev) => {
            if (isChecked) {
                return prev.some((v) => v.id === itemId)
                    ? prev
                    : [...prev, {id: itemId}]
            } else {
                return prev.filter((v) => v.id !== itemId)
            }
        })
    }

    const clearAllFilters = () => {
        window.history.pushState(null, '', window.location.pathname)
        window.location.reload()
    }
    useEffect(() => {}, [data, query])

    useEffect(() => {
        setErrorMessage(error?.data?.message)
    }, [error])

    useEffect(() => {
        if (paginationType === 'external') {
            refetch()
        }
    }, [urlParams, refetch, paginationType])

    const tableName = `${itemName}s`
    const {tableKeys, tableData, keyTypes, tableItems, totalItems} =
        getTableData(
            tableName,
            visibleKeys,
            data,
            page,
            pageSize,
            search,
            sort,
            paginationType
        )

    const calculatedIsAllSelected =
        tableData.length > 0 &&
        tableData.every((item) => checkBoxState[item.id])

    const handleSelectAll = (isChecked) => {
        const updatedCheckBoxState = tableData.reduce((acc, item) => {
            acc[item.id] = isChecked
            return acc
        }, {})

        setCheckBoxState((prevState) => ({
            ...prevState,
            ...updatedCheckBoxState,
        }))

        setSelectedVolunteers((prev) =>
            isChecked
                ? [...prev, ...tableData.map((item) => ({id: item.id}))]
                : prev.filter(
                      (volunteer) =>
                          !tableData.find((item) => item.id === volunteer.id)
                  )
        )

        setHeaderCheckboxState((prevState) => ({
            ...prevState,
            [page]: isChecked,
        }))
    }

    return (
        <Container>
            <TableTitleAndFilters
                title={i18n.t(`tables.${tableName}`)}
                itemType={itemType}
                useSearch
                allowAdd={allowAdd}
                addFunction={addFunction}
                itemName={itemName}
                hideTitle={hideTitle}
                textAlign={titleTextAlign}
                selectedToggleButton={selectedToggleButton}
                buttonsGroupOptions={buttonsGroupOptions}
                clearAllFilters={clearAllFilters}
                handleChange={handleSelectedType}
                isLoading={isFetching}>
                {filters}
            </TableTitleAndFilters>

            <StyledTableContainer component={Paper}>
                <StyledTable
                    sx={{minWidth: 650}}
                    {...p}
                    aria-label="simple table">
                    <TableHeadRow
                        isShowWhatsAppBtn={isShowWhatsAppBtn}
                        itemName={itemName}
                        onDelete={handleDelete}
                        allowExpand={allowExpand}
                        allowEdit={allowEdit}
                        tableKeys={tableKeys}
                        tableData={tableData}
                        currentPage={page}
                        selectedVolunteers={selectedVolunteers}
                        setSelectedVolunteers={setSelectedVolunteers}
                        isAllSelected={calculatedIsAllSelected}
                        setCheckBoxState={setCheckBoxState}
                        onSelectAll={handleSelectAll}
                        checkBoxState={checkBoxState}
                        headerCheckboxState={headerCheckboxState}
                        setHeaderCheckboxState={setHeaderCheckboxState}
                    />
                    <TableBody
                        setRowColor={setRowColor}
                        itemName={itemName}
                        tableKeys={tableKeys}
                        keyTypes={keyTypes}
                        tableItems={tableItems}
                        tableData={tableData}
                        checkBoxState={checkBoxState}
                        handleCheckboxChange={handleCheckboxChange}
                        onDelete={handleDelete}
                        allowExpand={allowExpand}
                        allowEdit={allowEdit}
                        editFunction={editFunction}
                        allowDelete={allowDelete}
                        CustomExpand={CustomExpand}
                        onRowClick={onRowClick}
                        deletePrompt={deletePrompt}
                        customRowActions={customRowActions}
                        selectedVolunteers={selectedVolunteers}
                        setSelectedVolunteers={setSelectedVolunteers}>
                        {children}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>
            <TablePagination
                itemName={itemName}
                paginationType={paginationType}
                totalItems={totalItems}
            />
            {allowDownload && (
                <DownloadLink
                    href={getDownloadLink(
                        itemName,
                        0,
                        totalItems,
                        search,
                        sort,
                        additionalQueries
                    )}>
                    <DownloadButton>Excel</DownloadButton>
                </DownloadLink>
            )}
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </Container>
    )
}

export default Table

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledTableContainer = styled(TableContainer)`
    width: 100%;
    margin-bottom: 0px;
`
const StyledTable = styled(RawTable)`
    width: 100%;
`

export const DownloadButton = styled(Button)`
    width: 100px;
    align-self: flex-end;
`

const DownloadLink = styled(Link)`
    align-self: flex-end;
`
